const RegisterForm = () => import(/* webpackChunkName: "register-form" */ '@/components/forms/Register')
const RegisterForRedeemForm = () => import(/* webpackChunkName: "register-form" */ '@/components/forms/RegisterForRedeem')

export default {
  name: 'register',
  components: {
    RegisterForm,
    RegisterForRedeemForm
  },
  computed: {
    isRedirectAfterRedeem() {
      return this.$route.query.ref === '/prakerja/redeem'
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
}
